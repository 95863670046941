import { Form as RouterForm } from "react-router-dom";

import { calculateOvertime, calculateWorkTime } from "../../util/finances";

import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import DetailedViewOffcanvas from "./DetailedViewOffcanvas";

import { AuthedUserContext } from "../../store/authed-user-context";
import { useContext } from "react";

const TableActionBar = ({
  plan,
  data,
  amount,
  relatedWorks,
  unrelatedWorks,
}) => {
  const { permissions } = useContext(AuthedUserContext);

  const schedule = plan.companyWorkSchedule
    ? data.company.workSchedule
    : plan.customProvisionSchedule;

  return (
    <>
      {permissions.canConfirmReportActions && (
        <>
          {unrelatedWorks.length > 0 && (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  В списке не должно быть выполненных работ, не привязанных ни к
                  одной услуге
                </Tooltip>
              }
            >
              <Button
                size="sm"
                variant="success"
                disabled
                style={{ pointerEvents: "none" }}
              >
                Утвердить
              </Button>
            </OverlayTrigger>
          )}
          {unrelatedWorks.length === 0 && (
            <RouterForm method="post" className="d-inline-block">
              <input
                name="relatedWorks"
                defaultValue={JSON.stringify(relatedWorks)}
                hidden
              />
              <input name="companyId" defaultValue={data.company._id} hidden />
              <input name="servicePlanId" defaultValue={plan._id} hidden />
              <input name="price" defaultValue={amount.price} hidden />
              <input
                name="additionalPrice"
                defaultValue={amount.additionalPrice}
                hidden
              />
              {}
              <Button
                size="sm"
                type="submit"
                variant="success"
                name="intent"
                value="confirmReportByContractor"
              >
                Утвердить
              </Button>
            </RouterForm>
          )}
          <DetailedViewOffcanvas
            worktimeWorks={
              calculateWorkTime(schedule, relatedWorks, plan.tariffingPeriod)
                .worktimeWorks
            }
            overtimeWorks={
              calculateOvertime(schedule, relatedWorks, plan.tariffingPeriod)
                .overtimeWorks
            }
            plan={plan}
            company={data.company}
          />
        </>
      )}
    </>
  );
};

export default TableActionBar;
