import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import ItemCard from "../../UI/ItemCard";

import { getWorkingStatus } from "../../util/get-working-status";
import { RiContractLine } from "react-icons/ri";

function CompanyItem({ item }) {
  const { alias, workSchedule, profileImagePath, servicePlans } = item;
  const workingStatus = workSchedule ? getWorkingStatus(workSchedule) : "";

  const Title = () => {
    return (
      <>
        <Image
          src={
            profileImagePath
              ? `${process.env.REACT_APP_ADDRESS}/uploads/${profileImagePath}`
              : "/companypic-placeholder.png"
          }
          style={{ maxHeight: "50px" }}
          className="me-3"
          roundedCircle
        />
        {alias} {item.servicePlans.length > 0 && <RiContractLine />}
      </>
    );
  };
  const badges = [];

  return (
    <ItemCard
      item={item}
      itemTitle="company"
      title={<Title />}
      badges={badges}
      detailsButton
      customDeleteMessage="Вы уверены? Все пользователи компании также будут удалены. Это
    действие нельзя отменить."
    >
      <Row>
        <Col>
          <div className="py-1">
            <a href={item.linkToMap} target="_blank" rel="noreferrer">
              {item.address}
            </a>
          </div>
          <div
            className={`py-1 ${workingStatus.isOpened ? "" : "text-danger"}`}
          >
            {workingStatus.verbose}
          </div>
        </Col>
      </Row>
    </ItemCard>
  );
}

export default CompanyItem;
